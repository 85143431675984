.show-card {
    border-radius: 10px;
    border: 2px solid $bright-back;
    background-color: $bright-back;
    @include flex(0, flex-start, flex-start, column);


    .thumbnail {
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
        border-radius: 10px;
    }

    .show-info {
        padding: 0 10px;

        p,
        h1 {
            margin: 0;
            word-break: break-all;
        }

        h1 {
            font-size: 28px;
            color: $main;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 5px;
        }

        .desc {
            font-size: 15px;
            color: rgba(255, 255, 255, 0.7);
        }
    }

    .stream {
        width: 100%;
        border-radius: 10px;
    }

    .button-wrapper {
        flex: 1;
        width: 100%;
        @include flex(0, flex-end, flex-end, column);
    }

    .stream-button {
        @include flex(0, center, center);
        width: 100%;
        border-radius: 10px;
        padding: 5px;
        background: radial-gradient(circle at 50% 50%, $main 0%, $sec 100%);
        color: white;
        cursor: pointer;
        border: none;
        font-size: 22px;


        span {
            font-size: 30px;
        }
    }
}