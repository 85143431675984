.create {
    padding: 20px;
    max-width: 700px;
    margin: auto;
    @include flex(0, space-between, center, column);
    color:white;
    
    .create-upper {
        @include flex(0, center, center);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        h1 {
            margin: 0;
            font-size: 24px;
            font-weight: 600;
            color:white;
        }

        img {
            width: 30px;
            cursor: pointer;
        }
    }

    .create-type {
        @include flex(0, space-evenly, center);

        p {
            font-size: 26px;
            color: white;
        }

        .active {
            color: $main;
            cursor: default;
            border-bottom: 2px solid $main;
        }

        .inactive {
            opacity: 0.7;
            cursor: pointer;
            color: white;
        }

        .inactive:hover {
            opacity: 1;
        }
    }

    .all-select-wrapper {
        width: 100%;
        @include flex(20px, space-between, center);
        flex-wrap: wrap;
        position: relative;
    }

    .h3-wrapper {
        width: 300px;
        h3 {
            margin-bottom: 10px;
            color: $main;
            font-weight: 100;
        }

        .select-wrapper {
            border: 2px solid $bright-back;
            border-radius: 10px;
            @include flex(0, center, center);

            select,
            input,
            .upload-video {
                width: 100%;
                color: white;
                background-color: $back-dark;
                font-size: 24px;
                padding-left: 10px;
                height: 40px;
                border-width: 0;
                border-radius: 10px;
            }

            label{
                height: 40px;
                padding: 0;
            }

            .date-special {
                width: 240px;
                font-size: 20px;
            }

            ::-webkit-calendar-picker-indicator {
                filter: invert(1);
                cursor: pointer;
            }

            select,
            input:focus {
                outline: 0px;
            }

            img {
                width: 40px;
                aspect-ratio: 1/1;
            }
        }
    }

    .fund-desc,.link {
        width: 100%;
        background: transparent;
        color: white;
        font-size: 20px;
        height: 150px;
        border: 2px solid $bright-back;
        border-radius: 10px;
        padding-left: 10px;
    }

    .link{
       height:unset;
       height: 40px;
    }

    .fund-desc:focus,.link:focus {
        outline: 0px;
    }

    .date {
        align-self: center;
        width: 100%;

        .date-input {
            width: fit-content !important;

        }
    }

    .area-wrapper {
        width: 100%;
        margin: auto;

        h3 {
            margin-bottom: 10px;
            color: $main;
        }

        textarea {
            width: 100% !important;
            border: 2px solid $bright-back;
            border-radius: 10px;
            padding: 10px;
            color: white;
            background-color: $back-dark;
            font-size: 24px;
            resize: none;
        }

        textarea:focus {
            outline: 0px;
        }
    }

    .checkbox-wrapper {
        @include flex(10px, center, center);
        margin-top: 10px;
        z-index: 1001;

        p {
            margin: 0;
            font-size: 24px
        }

        .checkbox {
            @include flex(0, center, center);
            width: 40px;
            height: 40px;
            border: 1px solid $bright-back;
            cursor: pointer;
            position: relative;
            z-index: 1001;

            span {
                font-size: 40px;
            }
        }
    }

    button {
        width: 200px;
        text-align: center;
        font-size: 32px;
        padding: 5px;
        border-width: 0;
        background-color: $main;
        color: white;
        border-radius: 10px;
        cursor: pointer;
        margin-top: 30px;
    }
}

.cr{
    height: unset;
}

@media (max-width: $mobile) {
    .create {
        height: 700px;
        overflow-y: auto;
        h1 {
            font-size: 30px;
            padding: 15px 0;
        }
        .create-type {
            p {
                font-size: 20px;
            }
        }

        .all-select-wrapper {
            @include flex(10px, center, center);
            width: 100%;

            .h3-wrapper {
                width: 100%;
                @include flex(10px, center, flex-start, column);

                h3 {
                    font-size: 16px;
                    font-weight: 200;
                    margin: 0;
                }

                .select-wrapper {
                    width: 100%;

                    select,
                    input {
                        padding: 8px 8px;
                        font-size: 18px;
                        height: 40px;
                    }

                    label{
                        height: 40px;
                    }

                    img {
                        width: 20px;
                    }
                }

                .address {
                    img {
                        width: 20px;
                        cursor: pointer;
                    }
                }
            }
        }

        .checkbox-wrapper {
            p {
                margin: 0;
                font-size: 18px
            }

            .checkbox {
                width: 25px;
                height: 25px;

                span {
                    font-size: 25px;
                }
            }
        }
    }
    .cr{
        height: 95vh;
        overflow: auto;
    }
}

@media(max-height:700px) {
    .create {
        padding-top: 0;
        .link{
            padding: 5px !important;
            font-size: 18px !important;
        }
    }
}