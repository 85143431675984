$mobile : 700px;
$middle : 965px;

$main : #F37F13;
$sec : #F29B00;
$gray : #818181;

$back-dark : #090c1f;
$color-dark : #f5e9ef;
$back-light : #ffffff;
$color-light : #424242;

$bright-back : #182443;
$table-back : #060816;
$mid-back : #0B0F26;
$bright-text : #F3F3F3;
$tutorial-back : #070A19;