.control {
    color: white;
    min-height: calc(100vh - 315px);
    position: relative;

    .control-banner {
        background-image: url("../imgs/home-banner.png");
        background-size: cover;
        width: 100%;
        height: 200px;
        @include flex(0, center, center);

        h1 {
            font-size: 48px;
        }
    }

    .control-wrapper {
        margin: auto;
        width: fit-content;
    }

    .list-count {
        margin: 0;
        font-size: 36px;
        margin-bottom: 20px;

        span {
            color: $main;
        }
    }
}

@media (max-width: 1670px) {
    .control {
        .control-banner {
            height: 110px;

            h1 {
                font-size: 24px;
            }
        }

        .list-count {
            font-size: 16px;
            margin-bottom: 20px;
            margin-left: 10px;
        }

        .control-wrapper {
            width: 95vw;
        }
    }
}