.chat-box {
    display: flex;
    width: 340px;
    flex-direction: column;
    position: relative;
    overflow-y: scroll;
    border-left: 1px solid;
    box-shadow: 0px 0px 10px 0px #000000;

    .chat-header {
        flex: 4;
        @include flex(0, space-between, center);
        border-bottom: 1px solid $bright-back;
        padding: 0 10px;

        h1 {
            font-size: 20px;
            margin: 0;
            margin-left: 10px;
            color: #fff;
        }

        span {
            font-size: 25px;
            color: #fff;
            opacity: 0.7;
        }
    }

    .reconnect-chat {
        width: 100%;
        text-align: center;
        text-decoration: underline;
        color: #AA0000;
        cursor: pointer;
        padding-bottom: 20px;
    }
}

.body-text {
    position: relative;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    flex: 32;
    background-color: $back-dark;
}

.chat-box-input {
    display: flex;
    flex: 6;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;

    input {
        width: 95%;
        height: 40px;
        border: none;
        background-color: $bright-back;
        padding-left: 20px;
        color: #ffff;
        border-radius: 10px;
        font-size: 16px;
    }

    input:focus {
        outline: 1px solid $main;
    }

    ::placeholder {
        color: rgba(255, 255, 255, 0.5);
        font-size: 16px;
    }
}

.message-text {
    padding: 5px;
    cursor: pointer;
    word-break: 'break-all';
    color: #ffffff;
    text-align: right;
    width: 100%;
    direction: rtl;
    margin: 0;

    span {
        margin: 0;
        margin-left: 0px;
        color: #ffffff;
        font-size: 16px;
    }
}

.nickName {
    margin-right: 5px;
    font-size: 20px;
}

.message-body {
    color: rgba(255, 255, 255, 0.85);
    margin-left: 5px;
    font-size: 16px;
    margin-right: 5px;
}

.avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
}

.ban-menu {
    position: absolute;
    width: 90%;
    left: 50%;
    background-color: black;
    border: 2px solid $main;
    border-radius: 10px;
    padding: 10px;
    color: white;
    transform: translateX(-50%);
    z-index: 1;

    .name {
        color: $main;
        font-size: 20px;
        text-align: center;
        margin-bottom: 10px;
    }

    .selects {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    select {
        flex: 1;
        font-size: 16px;
        padding: 5px;
        background-color: $bright-back;
        color: white;
        border: 1px solid $main;
        border-radius: 5px;
    }

    select:focus {
        outline: 0px solid $main;
    }

    .buttons {
        @include flex(10px, center, center, column);
        margin-top: 20px;
        gap: 10px;

        &>div:first-child {
            background-color: $main;
        }

        &>div:nth-child(2) {
            background-color: $bright-back;
        }

        div {
            width: 100%;
            height: 30px;
            border-radius: 10px;
            @include flex(0, center, center);
            cursor: pointer;
            border: 1px solid $main;
            height: 35px;
            width: 100%;
        }
    }
}

@media only screen and (max-width: 1750px) {
    .chat-box {
        display: flex;
        flex: 6;
    }
}

@media only screen and (max-width: 1300px) {
    .chat-box {
        display: flex;
        flex: 10;
    }
}

@media (max-width: 1100px) {
    .chat-box {
        display: flex;
        flex: 1;
        height: 100%;
        min-height: 95lvh;
        width: 270px;

        .chat-header {
            @include flex(0, center, center);
        }
    }

    .chat-box-input {
        flex: 2;

        input {
            width: 95%;
            height: 35px;
        }

        input:focus {
            outline: 0px solid $main;
        }
    }

    .chat-bar-mobile {
        height: 45px;
        color: white;
        background-color: $back-dark;
        @include flex(0, space-between, center);
        font-size: 16px;
        padding: 0 15px;

        span {
            font-size: 25px;
            opacity: 0.7;
        }
    }

    .ban-menu {
        .name {
            font-size: 16px;
            margin-bottom: 6px;
        }

        .selects {
            gap: 6px;
        }

        select {
            font-size: 14px;
            padding: 3px;
            background-color: $bright-back;
        }

        .buttons {
            margin-top: 10px;
            gap: 10px;

            div {
                width: 100%;
                height: 25px;
                border-radius: 10px;
                font-size: 16px;
            }
        }
    }
}