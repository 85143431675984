.dark {
    background-color: $back-dark;
    color: $color-dark;
}

.light {
    background-color: $back-light;
    color: $color-light;
}

.border-dark {
    border-color: $color-dark;
}

.border-light {
    border-color: $color-light;
}

.border-main {
    border-color: $main !important;
}

.color-dark {
    color: $color-dark;
}

.color-light {
    color: $color-light;
}

.back-light {
    background-color: $back-light;
}

.back-stream{
    background-color: #131838;
}

.back-dark {
    background-color: $back-dark;
}

.main-color,.color-main {
    color: $main
}
.mid-back{
    background-color: $mid-back;
}

.back-main{
    background-color: $main !important;
}
.main-back{
    background-color: $main !important;
}

.sec-color {
    color: $sec
}

.gray-color {
    color: $gray
}

.hover-main-svg:hover {
    path {
        fill: $main !important;
    }

    circle {
        fill: $main !important;
    }
}

.sec-svg {
    path {
        fill: $sec !important;
    }

    circle {
        fill: $sec !important;
    }
}

.hover-darker-svg:hover {
    path {
        fill: rgb(182, 182, 182) !important;
    }

    circle {
        fill: rgb(182, 182, 182) !important;
    }
}