.control-history {
    table {
        width: calc(100% - 50px);
        border: 1px solid #353840;
        border-radius: 10px;
        background-color: $table-back;
        margin-left: 10px;
        z-index: 0;
        margin-bottom: 30px;

        thead {
            border-top-left-radius: 10px;
            color: $main;
        }

        td {
            padding: 4px 10px;
            position: relative;
            padding: 20px;
        }

        .social{
            // @include flex(10px, center, center);
            img{
                width: 26px;
                height: 26px;
                border-radius: 50%;
                cursor: pointer;
                margin:0 10px;
            }
        }

        .actions {
            @include flex(10px, center, center);

            button {
                font-size: 16px;
                padding: 5px 10px;
                border-radius: 10px;
                color: white;
                cursor: pointer;
                border: none;
            }

            .accept,
            .unban {
                background-color: green;
            }

            .reject,
            .cancel,
            .ban {
                background-color: red;
            }

            .missions {
                background-color: rgb(0, 129, 123);
            }

            .button-stream {
                background-color: $main;
            }

            .share {
                background-color: brown;
            }
        }

        .thumbnail {
            padding: 0;
            height: 70px;

            img {
                height: 100%;
                aspect-ratio: 16/9;
                object-fit: cover;
                cursor: pointer;
                border-radius: 5px;
            }
        }

        tr {
            font-size: 20px;
            text-align: center;
        }

        .remove-ban {
            background-color: $main;
            color: white;
            border-radius: 10px;
            padding: 3px;
            cursor: pointer;
        }
    }

    tbody tr:nth-child(even) {
        background-color: $bright-back;
    }

    tbody tr:last-child {
        box-shadow: 0px 0px 0px 0px #818181;
    }

    .upper-creators {
        @include flex(30px, flex-start, center);

        .options-bar {
            @include flex(20px, center, center);
            width: max-content;
            overflow-x: scroll;
            margin: 0;
            padding: 0 !important;

            p {
                font-size: 20px;
            }
        }

        .creator-search {
            @include flex(0, center, center);
            width: 400px;
            height: 60px;
            border-radius: 10px;
            padding-bottom: 20px;


            * {
                border-width: 0;
            }

            input {
                height: 100%;
                flex: 1;
                border-bottom-left-radius: 10px;
                border-top-left-radius: 10px;
                color: white;
                background-color: $bright-back;
                padding-left: 10px;
                font-size: 20px;
            }

            button {
                text-align: center;
                padding: 0;
                height: 100%;
                color: white;
                background-color: $main;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                cursor: pointer;
                width: 40px;
            }

            input:focus {
                outline: 0;
            }
        }
    }
}

@media (max-width: $mobile) {
    .control-history {
        table {
            display: block;
            overflow-x: auto;
            white-space: nowrap;
            width: calc(100% - 20px);

            td {
                font-size: 14px;
                padding: 12px;
            }

            .thumbnail {
                height: 54px;
            }   
        }

        .creator-search {
            width: 300px;
            height: 30px;
            margin-left: 10px;
            width: calc(100% - 20px);

            input {
                padding-left: 10px;
                font-size: 18px;
            }

            button {
                text-align: center;
                padding: 0;
                height: 100%;
                color: white;
                background-color: $main;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                cursor: pointer;
                width: 40px;
            }
        }

        .upper-creators {
            @include flex(10px, flex-start, flex-start, column);
            padding-bottom: 10px;

            .options-bar {
                @include flex(10px, center, center);
                padding-left: 10px;

                p {
                    font-size: 16px;
                }
            }

            .creator-search {
                @include flex(0, center, center);
                width: 300px;
                height: 40px;
                padding-bottom: 0;

                input {
                    height: 100%;
                    flex: 1;
                    border-bottom-left-radius: 10px;
                    border-top-left-radius: 10px;
                    color: white;
                    background-color: $bright-back;
                    padding-left: 10px;
                    font-size: 20px;
                }

                button {
                    text-align: center;
                    padding: 0;
                    height: 100%;
                    color: white;
                    background-color: $main;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    cursor: pointer;
                    width: 40px;
                }

                input:focus {
                    outline: 0;
                }
            }
        }
    }
}