.options-bar {
    @include flex(75px, center, center);
    width: max-content;
    margin-bottom: 35px;
    overflow-x: scroll;
    width: 100%;

    p {
        font-size: 36px;
        opacity: 0.7;
        cursor: pointer;
        margin: 0;
        width: max-content;
        white-space: nowrap;
    }

    .chosen {
        opacity: 1;
        color: $main;
        border-bottom: 2px solid $main;
    }
}

@media (max-width: 1670px) {
    .options-bar {
        margin: auto;
        padding: 0 10px;
        @include flex(50px, flex-start, center);
        margin-bottom: 25px;

        p {
            font-size: 16px;
        }
    }
}