.list {
    width: 950px;
    border: 3px solid $bright-back;
    border-radius: 10px;
    padding: 20px;
    padding-bottom: 0;

    .event {
        background-color: $bright-back;
        border-radius: 10px;
        width: 100%;
        height: 90px;
        @include flex(0, space-between, center);
        padding: 0 20px;
        margin-bottom: 20px;
        position: relative;

        .video-error {
            color: red;
            position: absolute;
            top: 3px;
            left: 3px;
            cursor: pointer;    
        }

        .left {
            @include flex(20px, center, center);

            .details {
                @include flex(10px, center, flex-start, column);

                p {
                    opacity: 0.7;
                    margin: 0;
                }
            }

            .game {
                width: 50px
            }

            .top {
                @include flex(4px, center, center);

                img {
                    width: 37px;
                    aspect-ratio: 1/1;
                    border-radius: 50%;
                }

                p {
                    opacity: 1;

                }

                .img-wrapper {
                    width: 50px;
                    height: 37px;
                    @include flex(-10px, center, center);
                    color: $main;

                    img {
                        height: 100%;
                        aspect-ratio: 1/1;
                    }

                    .img0 {
                        position: absolute;
                        left: 0%;
                    }

                    .img1 {
                        position: absolute;
                        left: 15%;
                    }

                    .img2 {
                        position: absolute;
                        left: 40%;
                    }
                }
            }
        }

        .actions {   
            display: grid;
            grid-template-columns: 50% 50% ;
            grid-template-rows: 50% 50% ;
            color: $main;
            gap:6px;
            span{
                font-size: 30px;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 920px) {
    .list {
        width: 100%;
        border: 2px solid $bright-back;
        padding: 4px;

        .event {
            padding: 0 6px;
            margin-bottom: 4px;

            .video-error{
                top: 2px;
                left: 2px;
                font-size: 22px;
            }

            .left {
                @include flex(2px, center, center);

                .details {
                    @include flex(5px, center, flex-start, column);

                    p {
                        font-size: 14px;
                    }
                }

                .game {
                    width: 35px
                }

                .top {
                    @include flex(4px, center, center);

                    img {
                        width: 16px;
                    }

                    .img-wrapper {
                        width: 30px;
                        height: 16px;
                    }
                }
            }

            .right {
                @include flex(10px, center, center);

                div {
                    @include flex(2px, center, center, column);

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }

}