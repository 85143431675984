.underline {
    text-decoration: underline;
}

.hover-main:hover {
    color: $main;
}

.center {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.center-fixed {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.center-h {
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.center-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column !important;
}

.center-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.no-scroll {
    overflow-y: hidden;
    height: 100vh;
}

.non-appear {
    display: none !important;
}

.hidden {
    visibility: hidden;
}

.screen {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    background-color: black;
    z-index: 10;
}

.blur {
    opacity: 0.6;
}

.clickable {
    cursor: pointer;
}

.relative {
    position: relative;
}

.hover-main:hover {
    color: $main
}

.undecorate {
    text-decoration: none;
}

.circle {
    border-radius: 50%;
}
    
@media(max-height:700px) {
    .too-low-fix {
        height: 98vh;
        overflow-y: auto;
        padding-bottom: 100px;
    }
}


.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}