:root {
    --volume: 100%;
}

.stream-container {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    background-color: $back-dark;
    height: calc(100vh - 60px);
}

.preview-chosen{
    border-color: $main !important;
}

.video-preview {
    width: 100%;
    height: 150px;
    border: 2px solid $bright-back;
    margin-bottom: 5px;
    cursor: pointer;
}

.stream {
    display: flex;
    flex: 18;
    flex-direction: column;

    .stream-video {
        @include flex(0, center, center);
        flex: 18;
        background-size: cover;
        background-color: #000000;

        .timer {
            @include flex(0, center, center);
            font-size: 80px;
            margin: 0;
            padding: 0;
            color: white;
            margin-top: 20px;
            background-color: #3b3b3b2d;
        }

        video {
            width: 100%;
            aspect-ratio: 16/9;
            object-fit: cover;
            transform: scaleX(-1);
            max-height: 1100px;
        }
    }
}

.stream-control {
    flex: 1.5;
    @include flex(0, space-between, flex-start);
    padding: 10px;
    box-shadow: 0px -4px 6px -4px #000000;
    z-index: 1;

    .options {
        @include flex(40px, center, center);

        img {
            width: 30px;
            aspect-ratio: 1/1;
            cursor: pointer;
        }
    }

    .start {
        @include flex(22px, center, center);

        div {
            color: white;
            font-size: 20px;
            border-radius: 10px;
            cursor: pointer;
            width: 150px;
            height: 30px;
            @include flex(0, center, center);
        }

        .begin {
            background: radial-gradient(circle at 50% 50%, $main 0%, $sec 100%);
        }

        .end {
            background-color: #f31313;
        }

        .pause {
            background-color: $bright-back;
        }

        .timer {
            font-size: 20px;
            color: $main;
            cursor: default;
        }
    }

    .stream-timer {
        color: $main;
        margin: 0;
        font-size: 22px;
    }

    .details {
        @include flex(40px, center, center);
        color: white;
        font-size: 20px;
        height: min-content;

        img {
            width: 30px;
            aspect-ratio: 1/1;
        }

        div {
            @include flex(4px, center, center);

            p {
                margin: 0;
            }
        }
    }
}

.settings {
    flex: 3;
    color: white;
    padding: 0 5px;
    min-width: 160px;
    overflow-y: auto;

    .settings-upper {
        width: 100%;
        @include flex(10px, flex-start, center);
        border-bottom: 2px solid $bright-back;
        font-size: 24px;

        span {
            font-size: 30px;
        }
    }

    .option {
        @include flex(0, space-between, center);
        cursor: pointer;
        font-size: 20px;
        border-bottom: 2px solid $bright-back;

        p {
            margin: 8px 0;
        }
    }

    .sub {
        border-bottom: 2px solid $bright-back;
        padding: 10px 0;
        cursor: pointer;
        display: flex;

        p {
            margin: 0;
            margin-top: 2px;
            padding-left: 5px;
        }
    }

    div {
        word-break: break-word;
        word-wrap: break-word;
    }

    .range-wrapper {
        padding-right: 5px;
        margin-top: 10px;
        color: $main;
        @include flex(0, center, center);

        input[type="range"] {
            background: $bright-back;
            flex: 1;
            border-radius: 10px;
            background: linear-gradient(to right, $sec 0%, $main var(--volume), $bright-back var(--volume), $bright-back 100%);
        }

        input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            background-color: $sec;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            cursor: pointer;
        }

        input[type="range"]::-webkit-slider-thumb:hover {
            background-color: $main;
        }
    }
}

.no-camera {
    @include flex(10px, center, center, column);
    color: #f3f3f3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    .camera {
        font-size: 80px;
    }

    p {
        margin: 0;
        text-align: center;
    }

    a {
        color: $main;
    }

    span {
        color: $main;
        cursor: pointer;
    }

}

.confirm-exit {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    background-color: rgb(11, 11, 11);
    border-radius: 15px;
    width: 520px;
    padding: 20px;
    @include flex(0, center, center, column);

    img {
        width: 95px;
        aspect-ratio: 1/1;
        margin-bottom: 24px;
    }

    .pay-img {
        width: 100%;
        height: 200px;
    }

    .pay-img-mobile {
        width: 100%;
        aspect-ratio: unset;
    }

    h1 {
        margin: 8px;
        color: white;
        font-size: 28px;
    }

    p {
        margin-top: 0;
        margin-bottom: 65px;
        color: rgba($color: #f3f3f3, $alpha: 0.7);
        font-size: 20px;
        text-align: center;
    }

    div {
        @include flex(24px, center, center);

        div {
            width: 165px;
            height: 42px;
            border: 1px solid $main;
            color: white;
            border-radius: 10px;
            font-size: 20px;
            cursor: pointer;
        }

        div:hover {
            background-color: $main;
        }

        .action {
            background: radial-gradient(circle at 50% 50%, $main 0%, $sec 100%);
        }
    }

    .checkbox-wrapper {
        @include flex(10px, center, center);
        z-index: 1001;
        margin-top: -40px;

        p {
            margin: 0 !important;
            font-size: 20px
        }

        .checkbox {
            @include flex(0, center, center);
            width: 30px;
            height: 30px;
            border: 1px solid $bright-back;
            cursor: pointer;
            position: relative;
            z-index: 1001;
            margin-bottom: 10px;

            span {
                font-size: 30px;
            }
        }

        .checkbox:hover {
            background-color: transparent;
        }
    }

    .percent-wrapper {
        width: 100%;
        @include flex(0, center, center, column);
        z-index: 1001;
        margin-top: -40px;

        p {
            margin: 0;
            margin-bottom: 20px;
        }

        div {
            width: 320px;
            @include flex(10px, center, center);
            height: unset;
            border: 0;

            select,
            input {
                width: 165px;
                margin-bottom: 20px;
                color: white;
                background-color: $mid-back;
                font-size: 18px;
                padding: 5px 10px;
                border: 1px solid $main;
                border-radius: 10px;

            }

            select:focus,
            input:focus {
                outline: none;
            }
        }

        div:hover {
            background-color: $mid-back;
        }
    }
}

.agora-local-pc {
    width: 100%;
    aspect-ratio: 16/9;
    max-height: 940px;

    div {
        max-height: 100% !important;
    }
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    /* make scrollbar transparent */
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: transparent;
}

video::-webkit-media-controls-volume-slider {
    display: none;
}

video::-webkit-media-controls-mute-button {
    display: none;
}