.soltiz {
    .tasks-wrapper {
        @include flex(10px, flex-start, flex-start, column);

        .task {
            @include flex(5px, flex-start, center, column);
            width: 300px;
            background-color: $bright-back;
            padding: 5px;
            border-radius: 10px;

            textarea,
            input {
                border: 1px solid $back-dark;
                cursor: pointer;
                background-color: transparent;
                color: white;
                resize: none;
                font-size: 16px;
                padding: 5px;
                border-radius: 5px;
            }

            textarea {
                width: 100%;
            }

            input {
                flex: 1;
            }

            textarea:focus,
            input:focus {
                outline: none;
            }

            .lower {
                @include flex(10px, flex-start, center);
                height: 50px;
                width: 100%;
            }
        }
    }

    .add,
    .remove {
        @include flex(0, center, center);
        font-size: large;
        color: white;
        border-radius: 50%;
        text-align: center;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }

    .add {
        margin-top: 10px;
        background-color: $main;
    }

    .remove {
        background-color: $back-dark;
        color: red;
    }

    .buttons-wrapper {
        padding-bottom: 20px;
        @include flex(20px, center, center);
        margin-top: 20px;
        width: 100%;

        button,
        div {
            all: unset;
            width: 200px;
            text-align: center;
            font-size: 32px;
            padding: 5px;
            color: white;
            border-radius: 10px;
            cursor: pointer;
            margin: 0 10px;
        }

        button {
            background-color: $main;
        }

        div {
            background-color: $bright-back;
        }

        button:hover {
            background-color: darken($main, 15%);
        }
    }
}

@media (max-width: 700px) {
    .soltiz {
        padding: 10px;
        .tasks-wrapper {
            width: 100%;
            .task {
                width: 100%;
            }
        }
        .buttons-wrapper {
            button,
            div,button {
               width: 130px;
               font-size: 24px;
            }
        }
    }
}