.simple-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 420px;
    padding: 10px;
    background-color: $table-back;
    border-radius: 10px;
    z-index: 1000;
    color: white;
    border: 2px solid $bright-back;
    @include flex(0,center,center,column);

    .wrapper {
        width: 360px;
    }

    .percent-wrapper {
        width: 100%;
        @include flex(0, center, center, column);
        z-index: 1001;
        margin-top: 10px;

        p {
            margin: 0;
            margin-bottom: 20px;
        }

        div {
            width: 320px;
            @include flex(10px, center, center);
            height: unset;
            border:0;
            margin-top: 10px;
            p{
                margin: 0;
                width:50px;
                text-align: left;
            }
            select,
            input {
                width: 165px;
                margin-bottom: 20px;
                color: white;
                background-color: $mid-back;
                font-size: 18px;
                padding: 5px 10px;
                border: 1px solid $main;
                border-radius: 10px;
                margin: 0;
                flex: 1;

            }

            select:focus,
            input:focus {
                outline: none;
            }
        }
    }

    .player-select,
    .prize {
        outline: none;
        border: 1px solid $main;
        font-size: 24px;
        background-color: $bright-back;
        color: $main;
        border-radius: 10px;
        padding-left: 10px;
        width: 100%;
    }

    img{
        width: 75px;
        margin: auto;
        margin-bottom: 20px;
    }

    h1,
    p {
        margin: 0;
        text-align: center;
        font-weight: 100;
        line-height: 22px;
    }
    h1{
        margin-bottom: 10px;
    }

    p {
        text-align: center;
    }

    .buttons-wrapper {
        width: 100%;
        @include flex(0, space-around, center);
        margin-top: 40px;

        .bolder,
        .lighter {
            width: 160px;
            text-align: center;
            color: white;
            font-size: 24px;
            padding: 5px 0;
            cursor: pointer;
            border:2px solid $main;
            border-radius: 10px;
        }

        .bolder {
            background-color: $main;
            border-radius: 10px;
        }
    }
}

@media screen and (max-width: 700px) {
    .simple-popup{
        width: 95vw;
        max-width: 420px;
    }
}