@media (max-width: 1100px) {
    .stream-mobile {
        margin-top: 70px;
        @include flex(0, center, center);
        height: 99lvh;

        .left-wrapper {
            @include flex(0, center, center, column);
            flex: 1;
            height: 100%;
            width: 66vh;
            flex: 2;

            .stream-video-mobile {
                width: 100%;
                flex: 1;
                background-color: #000000;
                height:200px;
                @include flex(0, center, center);

                video {
                    aspect-ratio: 16/9;
                    object-fit: cover;
                    transform: scaleX(-1);
                    flex: 1;
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .lower {
                @include flex(0, space-around, center);
                width: 100%;
                background-color: $back-dark;
                padding: 3px;

                img {
                    width: 30px;
                    aspect-ratio: 1/1;
                }

                .smaller {
                    width: 25px;
                }
            }

            .upper {
                box-sizing: unset;
                width: 100%;
                background-color: $back-dark;
                padding: 5px;
                @include flex(0, space-between, center);
                color: white;

                img {
                    width: 28px;
                    aspect-ratio: 1/1;
                }

                .stream-timer {
                    color: $main;
                    margin: 0;
                    font-size: 18px;
                }

                .timer {
                    color: white;
                    font-size: 18px;
                }

                .detail-holder {
                    @include flex(22px, center, center);
                    padding-right: 10px;

                    div {
                        @include flex(5px, center, center);

                        img {
                            width: 22px;
                            aspect-ratio: 1/1;
                        }

                        p {
                            margin: 0;
                            font-size: 18px;
                        }
                    }
                }

                .end-event-mobile {
                    height: 28px;
                    color: white;
                    background-color: #f31313;
                    border-radius: 10px;
                    padding: 0 14px;
                    @include flex(0, center, center);
                    margin-left: 5px;
                }
            }
        }
    }

    .confirm-exit {
        width: 520px;
        padding: 20px;

        img {
            width: 60px;
            aspect-ratio: 1/1;
            margin-bottom: 12px;
        }

        h1 {
            font-size: 20px;
            font-weight: 100;
        }

        p {
            margin-bottom: 40px;
            font-size: 16px;
        }

        div {
            div {
                width: 120px;
                height: 30px;
                font-size: 16px;
            }
        }
    }

    .no-permission {
        padding: 0;
        font-size: 24px;

        .camera {
            font-size: 60px;
        }
    }

    .agora-local-mobile {
        width: 100%;
        aspect-ratio: 16/9;
        max-height: 100%;
    
        div {
            max-height: 100% !important;
        }
    }
}

.main-stream {
    margin: 0 !important;
    height: 100%;
}

.rotate-phone {
    @include flex(0, center, center, column);
    width: 90%;
    color: white;
    text-align: center;
}