.feed {
    .premium {
        border-bottom: 2px solid $bright-back;
        width: 100%;
        margin-bottom: 20px;

        .name-wrapper {
            @include flex(5px, flex-start, center);
            margin-bottom: 10px;

            p {
                margin: 0;
            }

            .left {
                @include flex(5px, flex-start, center);

                span {
                    color: $main;
                }
            }

            .edit-name {
                @include flex(20px, flex-start, center);
                flex: 1;
            }

            .edit-button {
                all: unset;
                background-color: $back-dark;
                cursor: pointer;
                height: 30px;
                width: 30px;
                border-radius: 8px;
                @include flex(0, center, center);
                border-radius: 50%;

                span {
                    color: $main;
                    font-size: 18px;
                }
            }

            .name {
                all: unset;
                flex: 1;
                color: white;
                background-color: transparent;
                font-size: 16px;
                margin: 0;
                padding-left: 5px;
            }

            .desc {
                font-size: 14px;
                opacity: 0.7;
            }

            .border {
                border-bottom: 1px solid $main;
            }
        }
    }

    .create {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        margin-bottom: 20px;
        height: unset;

        .create-button {
            @include flex(0, center, center);
            background-color: $main;
            color: white;
            cursor: pointer;
            border: none;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: darken($main, 10%);
            }

            span {
                font-size: 34px;
            }
        }
    }
}

.create-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 40px;

    .text-input {
        width: 100%;
        height: 100px;
        padding: 10px;
        border: 2px solid $bright-back;
        border-radius: 10px;
        resize: none;
        background-color: $back-dark;
        color: white;
    }

    .options {
        display: flex;
        gap: 10px;

        .option-button {
            padding: 10px 15px;
            background-color: $bright-back;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: lighten($bright-back, 10%);
            }

            &.selected {
                background-color: $main;
            }

            &:disabled {
                background-color: $back-dark;
                cursor: not-allowed;
            }
        }
    }

    .feed-image {
        @include flex(0, center, center);
        width: 100%;
        border-radius: 10px;
        margin-bottom: 20px;

        img {
            width: 100%;
            object-fit: contain;
            max-height: 832px;
            border-radius: 10px;
            cursor: pointer;
        }
    }

    .poll-options {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .poll-option {
            @include flex(10px, flex-start, center);

            label {
                cursor: pointer;
                border: none;
            }

            img {
                width: 125px;
                height: 125px;
                object-fit: cover;
                border-radius: 10px;
            }

            .remove-img {
                cursor: pointer;
                background-color: $main;
                border-radius: 10px;
                position: absolute;
                left: 5px;
                top: 5px;
                z-index: 1;
            }

            span {
                cursor: pointer;
            }

            .poll-input {
                padding: 8px;
                border: 2px solid $bright-back;
                border-radius: 10px;
                background-color: $back-dark;
                color: white;
                flex: 1;
            }
        }

        button {
            color: white;
            padding: 8px;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            transition: background-color 0.3s ease;
        }

        .add-option {
            background-color: $main;

            &:hover {
                background-color: darken($main, 5%);
            }
        }

        .remove-option {
            background-color: $bright-back;

            &:hover {
                background-color: lighten($bright-back, 5%);
            }
        }
    }

    .submit-section {
        @include flex(30px, center, center);
        height: 80px;

        button {
            padding: 10px;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            width: 120px;
            font-size: 20px;
            color: white;
        }

        .submit-button {
            background-color: $main;



            &:hover {
                background-color: darken($main, 10%);
            }
        }

        .cancel-button {
            background-color: $back-dark;
            border: 1px solid $bright-back;

            &:hover {
                background-color: darken($back-dark, 10%);
            }
        }
    }
}

@media screen and (max-width: 852px) {
    .feed {
        .premium {
            .name-wrapper {
                @include flex(5px, flex-start, flex-start,column);
                .edit-name{
                    width: 100%;
                    gap: 5px;
                }
                .edit-button {
                    span {
                        color: $main;
                        font-size: 22px;
                    }
                }
            }
            
        }
    }
}