.login {
    @include flex(0, flex-start, center, column);
    min-height: calc(100vh - 315px);
    color: white;
    width: fit-content;
    margin: auto;

    p {
        color: white;
    }

    form {
        @include flex(0, flex-start, center, column);
    }

    * {
        margin: 0;
    }

    h1 {
        font-size: 48px;
        font-weight: 200px;
        margin-top: 100px;
        margin-bottom: 30px;
    }

    p,
    h4,
    h5 {
        font-size: 24px;
        font-weight: 100;
        align-self: flex-start;
    }

    p {
        margin-bottom: 30px;
    }

    h5 {
        opacity: 0.7;
    }

    h4 {
        color: $main;
        display: flex;
        cursor: pointer;

        span {
            color: white;
        }
    }

    .phone-wrapper {
        @include flex(20px, center, center);
        width: 100%;
        margin-bottom: 40px;

        select,
        input {
            color: white;
            background-color: $back-dark;
            padding: 10px 5px;
            border-width: 0;
            border-radius: 10px;
            font-size: 32px;
            border: 2px solid $bright-back;
        }

        select {
            width: 150px;
        }

        input {
            width: 300px;
            flex: 1;
        }
    }

    button {
        all: unset;
        width: 200px;
        text-align: center;
        font-size: 32px;
        padding: 5px;
        background-color: $main;
        color: white;
        border-radius: 10px;
        cursor: pointer;
        margin-top: 100px;
    }

    .code {
        width: 100%;
        @include flex(0, space-between, center);
        margin-bottom: 20px;

        .digit {
            width: 40px;
            height: 60px;
            text-align: center;
            font-size: 32px;
            border: 2px solid $bright-back;
            border-radius: 10px;
            background-color: $back-dark;
            color: white;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    select:focus,
    input:focus {
        outline: none;
    }

}

@media (max-width: $mobile) {
    .login {
        width: 100%;
        padding: 0 15px;
        min-height: calc(100vh - 65px);

        h1 {
            font-size: 24px;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        p,
        h5 {
            font-size: 16px;
            opacity: 0.7;
        }

        p {
            margin-bottom: 30px;
        }

        .phone-wrapper {
            @include flex(10px, center, center);
            width: 100%;
            margin-bottom: 30px;

            select,
            input {
                padding: 5px;
                font-size: 16px;
            }

            select {
                width: 100px;
            }

            input {
                width: 100px;
            }
        }

        button {
            width: 160px;
            font-size: 24px;
            margin-top: 40px;
        }

        .code {

            .digit {
                width: 50px;
                text-align: center;
                font-size: 20px;
                padding: 0;
            }
        }
    }
}