.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $back-dark;
    color: white;
    border: 2px solid $bright-back;
    border-radius: 10px;
    width: 700px;
    animation: fadeIn 0.4s ease;

    .popup-support {
        padding: 10px;
        @include flex(0, center, center, column);

        p {
            font-size: 20px;
            margin: 0;
            margin-bottom: 20px;
        }

        input,
        textarea {
            width: 100%;
            padding: 10px;
            margin-bottom: 10px;
            border-radius: 10px;
            background-color: $bright-back;
            color: white;
            border: 2px solid $main;
        }

        textarea {
            height: 200px;
        }

        button {
            background-color: $main;
            border-radius: 10px;
            padding: 5px 20px;
            font-size: 20px;
            cursor: pointer;
            color: white;
        }
    }

    .thumbnail-popup{
        width: 100%;
        aspect-ratio: 16/9;
        border-radius: 10px;
        object-fit: cover;
    }

    div {
        .wallet-connect {
            margin-top: 0;
            border-radius: 10px;
            padding-bottom: 0px;

            p {
                padding: 0 10px;
            }

            section {
                border-top: 1px solid gray;
                border-radius: 10px;
                border-bottom-width: 0px;
            }
        }

        .extension-wrapper {
            padding-bottom: 40px;

            .extension {
                height: unset;
                text-align: center;
                padding: 10px;
                border-radius: 10px;
                padding-bottom: 20px;
                color: white;
                background-color: $back-dark;
            }
        }

    }

    .wellcome {
        padding-bottom: 20px;

        h1 {
            font-size: 32px;
            text-align: center;
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    .event-link {
        padding-bottom: 20px;

        p {
            text-align: center;
            font-size: 24px;
        }

        .share-wrapper {
            width: 90%;
            @include flex(20px, center, center, column);
            margin: auto;
            margin-bottom: 30px;
            margin-top: 30px;

            .copy {
                @include flex(10px, center, center);
                width: 90%;
                margin: auto;
                border: 4px solid $bright-back;
                border-radius: 10px;
                padding: 5px 10px;

                span {
                    text-align: center;
                    font-size: 16px;
                    color: $gray;
                    text-decoration: underline;
                    max-width: 660px;
                    word-break: break-all;
                }

                img {
                    width: 20px;
                    aspect-ratio: 1/1;
                    cursor: pointer;
                }
            }
        }

        .buttons {
            width: 100%;
            @include flex(30px, center, center)
        }

        .share-button {
            width: 40px;
            aspect-ratio: 1/1;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .whatsapp {
            background-image: url('../imgs/share/whatsapp.png');
        }

        .email {
            background-image: url('../imgs/share/email.png');
        }

        .telegram {
            background-image: url('../imgs/share/telegram.png');
            width: 45px;
            margin-top: 6px !important;
        }

        .facebook {
            background-image: url('../imgs/share/facebook.png');
        }
    }

    .created {
        @include flex(0, center, center, column);
        padding: 20px;

        img {
            width: 135;
            aspect-ratio: 1/1;
        }

        h1 {
            font-size: 36px;
            margin: 20px 0;
            text-align: center;
        }

        p {
            font-size: 24px;
            margin: 0;
            margin-bottom: 40px;
            text-align: center;
            opacity: 0.7;
        }
    }

    .bought {
        padding-bottom: 20px;

        .upper-navbar {
            background-color: $bright-back;
            padding: 20px;
            @include flex(10px, space-between, center);
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;

            svg:hover {
                cursor: pointer;

                circle {
                    fill: rgb(182, 182, 182) !important;
                }
            }

            div {
                @include flex(10px, space-between, center);

                img {
                    width: 70px;
                    height: 70px;
                    object-fit: cover;
                    border-radius: 50%;
                }

                h1 {
                    font-size: 28px;
                    margin: 0;
                    font-weight: 100;
                }

                select{
                    background-color: $bright-back;
                    color: $main;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    font-size:28px; 
                    -webkit-appearance:button;
                }
            }
        }

        h1 {
            text-align: center;
            font-size: 36px;
            margin: 25px 0;

        }

        .inner-bought {
            @include flex(30px, flex-start, flex-start);
            font-size: 20px;
            padding: 20px;

            img {
                width: 200px;
                aspect-ratio: 2/3;
                object-fit: cover;
            }

            .contact-creator {
                width: 30px;
                height: 30px;
            }

            .headers {
                height: 300px;
                @include flex(35px, flex-start, flex-start, column);

                p {
                    margin: 0;
                    font-weight: 600;
                    color: white;
                    width: max-content;
                }
            }

            .details {
                height: 300px;
                @include flex(35px, flex-start, flex-start, column);

                p {
                    margin: 0;
                    font-weight: 300;
                    color: rgba(255, 255, 255, 0.8);
                }

                img {
                    width: 25px;
                    height: 25px;
                    cursor: pointer;
                }

            }

            .player-details {
                height: 300px;
                @include flex(0, space-between, flex-start, column);

                p {
                    @include flex(20px, flex-start, center);
                    margin: 0;
                    font-weight: 600;

                    span {
                        font-weight: 300;
                        color: rgba(255, 255, 255, 0.8);
                    }
                }

                img {
                    width: 35px;
                    height: 35px;
                    cursor: pointer;
                }
            }
        }
    }
    .buttons-wrapper {
        width: 100%;
        @include flex(40px, center, center);
        margin-top: 20px;

        .bolder,
        .lighter {
            width: 160px;
            text-align: center;
            color: white;
            font-size: 24px;
            padding: 10px 0;
            cursor: pointer;
            border:2px solid $main;
            border-radius: 10px;
        }

        .bolder {
            background-color: $main;
            border-radius: 10px;
        }
    }
}

.done {
    cursor: pointer;
    background-color: $main;
    font-size: 36px !important;
    color: white;
    padding: 10px 55px !important;
    width: fit-content;
    margin: auto;
    border-radius: 12px;
}

.popup-close-mobile {
    position: absolute;
    bottom: 55px;
    width: 100%;
    @include flex(0, center, center);
    background: rgba(0, 0, 0, 0);
    font-size: 24px;
    color: white
}

@media (max-width: $mobile) {
    .popup {
        width: 95vw;

        .popup-video {
            height: 300px;
        }

        .popup-support {
            h1 {
                font-size: 24px;
                margin: 6px;
            }

            p {
                font-size: 16px;
                margin: 0;
                margin-bottom: 10px;
            }

            textarea {
                height: 150px;
            }
        }

        div {
            .wallet-connect {
                section {
                    border-right-width: 0;
                    border-left-width: 0;
                }
            }
        }

        .event-link {
            p {
                font-size: 18px;
            }

            .copy {
                width: 90% !important;

                span {
                    font-size: 16px !important;
                    word-break: break-all;
                }

                img {
                    width: 16px !important;
                }
            }
        }

        .bought {
            padding: 0;
            padding-bottom: 30px;

            h1 {
                font-size: 24px;
                margin-top: 12px;
                margin-bottom: 12px;
            }

            .inner-bought {
                @include flex(25px, flex-start, flex-start, column);
                font-size: 20px;
                padding: 0;
                margin-bottom: 55px;

                img {
                    width: 100%;
                    aspect-ratio: 3/1;
                    object-fit: cover;
                }

                div {
                    padding: 0 15px;

                    h3 {
                        font-size: 18px;
                        font-weight: 600;
                        color: white;
                        margin: 0;
                        margin-bottom: 5px;
                    }

                    p {
                        font-size: 18px;
                        font-weight: 300;
                        color: rgba(255, 255, 255, 0.8);
                        margin: 0;
                    }
                }
            }
        }

        .created {
            padding: 10px;

            img {
                width: 80px;
            }

            h1 {
                font-size: 24px;
                margin: 14px 0;
            }

            p {
                font-size: 16px;
                margin-bottom: 30px;
            }
        }

        .done {
            font-size: 26px !important;
            padding: 8px 35px !important;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        top: 100vh
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
        top: 50%;
    }
}