.home {
    color: white;
    flex: 1;
    width: 66%;
    max-width: 1500px;
    margin: auto;

    .options-bar{
        justify-content: flex-start;
        margin-bottom: 10px;
        p{
            font-size: 18px;
        }
    }

    .events-container {
        margin: auto;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        gap: 20px;
        margin-bottom: 30px;
    }

    .no-events {
        margin: auto;
        margin-top: 50px;
        @include flex(50px, center, center, column);
        padding: 0 15px;

        h1 {
            font-size: 32px;
            text-align: center;
        }

        img {
            width: 220px;
        }
    }

    .search-user {
        @include flex(0, center, center);
        width:97vw;
        max-width: 400px;
        margin-bottom: 30px;

        input {
            flex: 1;
            padding: 5px 10px;
            font-size: 16px;
            background-color: $bright-back;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            color: white;
            border: none;
        }

        input:focus {
            outline: none;
            border: none;
        }

        button,
        div {
            height: 28px;
            width: 35px;
            border: none;
            cursor: pointer;
        }

        button {
            background-color: $main;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        div{
            @include flex(0, center, center);
background-color: $bright-back;
        }
    }

    .followers-container {
        margin: auto;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 3fr));
        gap: 20px;
        padding: 0 10px;
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid white;

        div {
            padding: 10px;
            border-radius: 10px;
            background-color: $bright-back;
            @include flex(10px, space-between, center);

            img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                object-fit: cover;
            }

            p {
                font-size: 20px;
            }

            button {
                padding: 5px 10px;
                border-radius: 5px;
                color: white;
                font-size: 16px;
                cursor: pointer;
                transition: 0.3s;
                background-color: red;
                border: none;
            }
        }
    }
}

@media (max-width : 1267px) {
    .home {
        width: 100%;
    }
}

@media (max-width: $mobile) {
    .home {
        .home-banner {
            height: 120px;

            h1 {
                font-size: 32px;
                text-align: center;
            }
        }

        .events-container {
            width: 100%;
            grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
            gap: 40px;
            padding: 0 10px;
        }

        .event-card {
            .event-inner {
                @include flex(10px, flex-start, flex-start);
                font-size: 16px;
                padding: 8px;
            }
        }

        .no-events {
            margin-top: 0px;
            @include flex(10px, center, center, column);

            h1 {
                font-size: 20px;
            }

            img {
                width: 160px;
            }
        }
    }
}