.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    color: white;
    background-color: $back-dark;
    @include flex(40px, space-between, center);
    font-size: 24px;
    z-index: 100;
    padding: 8px 1vw;
    box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);

    .logo {
        height: 35px;
        object-fit: contain;
    }

    .create-button,
    .join-button {
        font-size: 18px;
        padding: 6px;
        width: 150px;
        border-radius: 8px;
        color: white;
        cursor: pointer;
        position: relative;
        border: 2px solid $main;
        text-align: center;
    }

    .create-button {
        background-color: $main;
    }

    a {
        text-decoration: none;
    }

    .header-options {
        @include flex(30px, flex-start, center);

        a {
            padding-top: 4px;
        }

        .bar-logo {
            height: 35px;
            object-fit: contain;
            cursor: pointer;
        }

        .header-user-img {
            width: 34px;
            aspect-ratio: 1/1;
            border-radius: 50%;
            object-fit: cover;
            cursor: pointer;
            margin-top: 6px;
        }

        .admin {
            width: 30px;
            aspect-ratio: 1/1;
            object-fit: cover;
            cursor: pointer;
        }

        .header-user-img:hover {
            outline: 2px solid $main;
        }
    }

    .menu-icon {
        font-size: 40px;
    }
}

.footer-mobile {
    position: fixed;
    background-color: $back-dark;
    width: 100%;
    bottom: 0;
    right: 0;
    z-index: 5;
    height: 36px;
    font-weight: 100;
    @include flex(0, space-around, center);
    box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.25);

    img {
        height: 25px;
        object-fit: contain;
    }

    .user-img {
        height: 30px;
        width: 30px;
        object-fit: cover;
    }

    .create-menu {
        position: absolute;
        top: 0;
        transform: translate(0, -100%);
        width: 100%;
        background-color: $bright-back;
        box-shadow: 0px -4px 5px -3px lighten($bright-back, 20%);

        a {
            text-decoration: none;
            color: white;
        }

        div {
            text-align: center;
            height: 50px;
            font-size: 24px;
            background-color: $bright-back;
            @include flex(0, center, center);
            border-bottom: 2px solid $back-dark;
            margin: 0;
            color: white;
        }
    }
}

@media (max-width: $mobile) {
    .header {
        @include flex(6px, space-between, center);
        padding: 8px 10px;

        .admin {
            width: 30px;
        }
    }

    .header-options {
        @include flex(10px, flex-start, center);
        gap: 10px !important;
        overflow-x: scroll;
        width: max-content;
    }
}