.register {
    margin: auto;
    @include flex(0, center, center, column);
    color: white;

    h1 {
        font-size: 40px;
        color: $main;
        margin-bottom: 20px;
    }

    p{
        max-width: 100%;
        margin: 0;
        margin-bottom: 20px;
        max-width: 430px;
    }

    .phase1 {
        width: min-content;
    }

    .form {
        @include flex(5px, flex-start, flex-start, column);

        h3 {
            color: $main;
            font-size: 32px;
            margin: 0;
            font-weight: 400;
        }

        input {
            font-size: 32px;
            padding: 5px 2px;
            border: 2px solid $bright-back;
            background-color: $back-dark;
            color: white;
            border-radius: 10px;
            padding-left: 10px;
            margin-bottom: 20px;
            width: 100%;
        }

        label {
            width: 100%;
            margin-bottom: 10px;

            .upload-img {
                width: 100%;
                overflow: hidden;
                border: 2px solid $bright-back;
                word-wrap: break-word;
                @include flex(0, center, center);
                border-radius: 10px;
                cursor: pointer;

                img {
                    width: 50px;
                    aspect-ratio: 1/1;
                }
            }
        }

        input:focus {
            outline: 0px;
        }

        .social-wrapper {
            width: 100%;
            @include flex(10px, center, center);

            input {
                width: unset;
                flex: 1;
                margin: 0;
            }

            img {
                height: 40px;
                aspect-ratio: 1/1;

            }
        }

        button {
            width: 100%;
            text-align: center;
            font-size: 32px;
            padding: 5px;
            border-width: 0;
            background-color: $main;
            color: white;
            border-radius: 10px;
            cursor: pointer;
            margin-top: 30px;
            align-self: center;
            margin-bottom: 60px;
        }
    }
}

.register-complete{
    border: 3px solid $main;
    border-radius: 10px;
    padding: 10px;
    max-width: 600px;
    h1{
        text-align: center;
    }
}

@media (max-width: $mobile) {
    .register {
        width: 98vw;
        margin: auto;
        padding: 10px;

        input{
            width: 100% !important;
        }

        h1 {
            font-size: 24px;
            margin-bottom: 0;
        }

        .phase1 {
            width: 90%;
            @include flex(10px, center, center, column);

            h3 {
                font-size: 18px;
                align-self: flex-start;
            }

            input,
            label {
                font-size: 18px;
                width: 100%;
            }

            .upload-img {
                img {
                    width: 30px;
                }
            }
        }
    }
}